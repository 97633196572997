import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Loader/Loader";

import SubMenu from "../SubMenu/SubMenu";
import Filter from "../Filter/Filter";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  decoded,
  API,
  headers,
  catchErrors,
  displayNotification,
} from "../../variables/Variables";
import {
  displayNewProduct,
  editNewProduct,
  deleteNewProduct,
} from "../../redux/NewProduct/newproduct.action";
import { getSelectOption } from "../../redux/Utils/utils.action";
import { EDIT_PRODUCT } from "../../redux/NewProduct/newproduct.type";
import { confirmAction } from "../Confirmation/Confirmation";
import {
  Button,
  Empty,
  List,
  Descriptions,
  Icon,
  Typography,
  Select,
  Badge,
  Pagination,
} from "antd";

import ProductTimeline from "../ProductTimeline/ProductTimeline";
import RequestModal from "../Request/RequestModal";
import { viewAttachment } from "../Request/RequestModal";
import AttachmentModal from "../Attachments/AttachmentModal";

// Inside the Itemlist component

const { Text } = Typography;
const { Option } = Select;
function Itemlist(props) {
  const [exporting, setExporting] = useState(false);
  const {
    products,
    displayNewProduct,
    editNewProduct,
    deleteNewProduct,
    productLength,
    _setState,
    _state,
    onSelectProduct,
    selectOptions,
    isSelectOptionLoaded,
    updateProductList,
    getSelectOption,
  } = props;

  const [filter, setFilter] = useState({
    company: "All",
    status: "ON GOING",
    commodity: "All",
    projectname: "",
  });

  const [paginate, setPaginate] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    displayNewProduct(filter, paginate.current, paginate.pageSize);
    if (!isSelectOptionLoaded) getSelectOption();
  }, [
    displayNewProduct,
    filter,
    getSelectOption,
    isSelectOptionLoaded,
    paginate,
  ]);

  const onClickFilter = () => {
    displayNewProduct(filter, paginate.current, paginate.pageSize);
  };

  const onResetFilter = () => {
    setFilter({
      company: "All",
      status: "ON GOING",
      commodity: "All",
      projectname: "",
    });
    setPaginate({ currency: 1, pageSize: 10 });

    setTimeout(() => {
      displayNewProduct(
        {
          company: "All",
          status: "ON GOING",
          commodity: "All",
          projectname: "",
        },
        1,
        5
      );
    }, 700);
  };

  const onPageChange = (page) => {
    setPaginate({ ...paginate, current: page });
    displayNewProduct(filter, page, paginate.pageSize);
  };

  const onSizeChange = (page, pageSize) => {
    setPaginate({ ...paginate, pageSize });
    displayNewProduct(filter, page, pageSize);
  };

  const onClickStatus = (data) => {
    const key = products.findIndex((v) => v.id === data.id);
    const copyProduct = [...products];
    copyProduct[key] = {
      ...copyProduct[key],
      editableStatus: !copyProduct[key].editableStatus,
    };
    updateProductList(copyProduct[key]);
  };

  //export
  const onClickExport = () => {
    setExporting(true); // Set loading state to true
    const { company, projectname, status, commodity } = filter;

    const company_filter = company === "All" ? "" : `&company=${company}`;
    const status_filter = status === "All" ? "" : `&status=${status}`;
    const commodity_filter =
      commodity === "All" ? "" : `&commodity=${commodity}`;
    const projectname_filter =
      projectname.trim() === "" ? "" : `&projectName=${projectname}`;

    axios({
      url:
        API +
        "product/export?" +
        company_filter +
        status_filter +
        commodity_filter +
        projectname_filter,
      method: "get",
      responseType: "blob",
      ...headers(),
    })
      .then((res) => {
        displayNotification("success", "File successfully generated");
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "NPD.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
      })
      .catch((err) => {
        catchErrors(err);
      })
      .finally(() => {
        setExporting(false); // Reset loading state
      });
  };

  const [viewTimeline, setViewTimeline] = useState({ view: false, id: null });

  const [viewRequest, setViewRequest] = useState({ view: false, id: null });

  const [viewAttachFiles, setViewAttachFiles] = useState({
    view: false,
    id: null,
  });

  const isCS = decoded().department === "cs" ? true : false;
  const isEngg = decoded().department === "engg" ? true : false;
  const isAdmin = decoded().type === "admin" ? true : false;

  const IconText = (prop) => {
    const p = { ...prop };
    return (
      <span onClick={p.onClick}>
        <Icon type={p.type} style={{ ...p.style, marginRight: 8 }} />
        {p.text}
      </span>
    );
  };

  return (
    <Fragment>
      <SubMenu />
      <br />
      <Filter
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        onResetFilter={onResetFilter}
        paginate={paginate}
        onClickFilter={onClickFilter}
        selectOptions={selectOptions}
        filter={filter}
        setFilter={setFilter}
      />
      <div style={{ textAlign: "right" }}>
        <Pagination
          current={paginate.current}
          total={productLength}
          pageSize={paginate.pageSize}
          defaultPageSize={paginate.pageSize}
          showSizeChanger={true}
          onChange={onPageChange}
          onShowSizeChange={onSizeChange}
          pageSizeOptions={["5", "10", "15", "25", "50"]}
        />
      </div>
      {isCS ? (
        <Button
          icon="form"
          onClick={() => _setState({ ..._state, productModalVisible: true })}
        >
          Add Item
        </Button>
      ) : null}
      <Button
        title="Refresh content"
        onClick={() => onClickFilter()}
        style={{ marginLeft: "2px" }}
        icon="reload"
      ></Button>
      {products.length > 0 ? (
        <Fragment>
          <List
            header={
              <Fragment>
                <h3>List of products for development</h3>
                <Button
                  onClick={() => onClickExport()}
                  type="primary"
                  icon="file-excel"
                  disabled={exporting} // Disable button when exporting is in progress
                >
                  {exporting ? <Loader /> : "Export to Excel"}
                </Button>
              </Fragment>
            }
            itemLayout="vertical"
            size="large"
            title="New Product Development"
            dataSource={products}
            renderItem={(value) => {
              const actionCs = [
                <IconText
                  style={{ color: "#3498db" }}
                  onClick={() => onSelectProduct(value)}
                  type="edit"
                  text="Edit"
                />,
                <IconText
                  onClick={() =>
                    confirmAction(deleteNewProduct, null, value.id)
                  }
                  style={{ color: "#e74c3c" }}
                  type="delete"
                  text="Delete"
                />,
              ];
              const rejectremarks = value.rejectionremarks;
              let notifCount = 0;

              if (isCS) {
                notifCount +=
                  value.rfq_status === "W/ REMARKS" ||
                  value.rfq_status === "ISSUED"
                    ? 1
                    : 0;
                notifCount +=
                  value.rfs_status === "W/ REMARKS" ||
                  value.rfs_status === "ISSUED"
                    ? 1
                    : 0;
                notifCount +=
                  value.rfd_status === "W/ REMARKS" ||
                  value.rfd_status === "ISSUED"
                    ? 1
                    : 0;
              }

              if (isEngg) {
                notifCount += value.rfq_status === "W/ REQUEST" ? 1 : 0;
                notifCount += value.rfs_status === "W/ REQUEST" ? 1 : 0;
                notifCount += value.rfd_status === "W/ REQUEST" ? 1 : 0;
              }

              if (value.status !== "ON GOING") notifCount = 0;

              const defaultAction = [
                <Badge count={notifCount}>
                  <IconText
                    style={{ color: "#27ae60" }}
                    type="interaction"
                    onClick={() =>
                      setViewRequest({
                        view: true,
                        id: value.id,
                        project_name: value.project_name,
                        companyname: value.companyname,
                        status: value.status,
                      })
                    }
                    text="View Request"
                  />
                </Badge>,
                <IconText
                  style={{ color: "#3498db" }}
                  type="calendar"
                  onClick={() => setViewTimeline({ view: true, id: value.id })}
                  text="View Timeline"
                />,
                <IconText
                  style={{ color: "#3498db" }}
                  type="file"
                  onClick={() =>
                    setViewAttachFiles({ view: true, id: value.id })
                  }
                  text="View Attachments"
                />,
              ];
              const listAction =
                isCS && (value.status === "ON GOING" || isAdmin)
                  ? defaultAction.concat(actionCs)
                  : defaultAction;

              const opt = [
                "APPROVED PENDING FOR PO",
                "APPROVED W/ PO",
                "ON GOING",
                "CLOSED",
              ].map((data, i) => {
                if ((i === 0 || i === 1) && value.rfq_status !== "APPROVED")
                  return false;

                return (
                  <Option key={i} value={data}>
                    {data}
                  </Option>
                );
              });

              return (
                <List.Item key={value.id} actions={listAction}>
                  <Descriptions
                    bordered
                    title={
                      value.id.toString().padStart(6, "0") +
                      " " +
                      value.project_name +
                      " - " +
                      value.status
                    }
                    border
                    // column={{ lg: 4, md: 2, sm: 1 }}
                    column={4}
                    size="small"
                  >
                    <Descriptions.Item label="Customer" span={2}>
                      {value.companyname}
                    </Descriptions.Item>
                    <Descriptions.Item label="Item Description" span={2}>
                      {value.item_description}
                    </Descriptions.Item>
                    <Descriptions.Item label="Category" span={2}>
                      {value.category}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date" span={1}>
                      {value.created_at}
                    </Descriptions.Item>
                    <Descriptions.Item label="Sales Person" span={1}>
                      {value.sales_person}
                    </Descriptions.Item>
                    <Descriptions.Item label="Part No." span={1}>
                      {value.part_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="NPD No." span={1}>
                      {value.id.toString().padStart(6, "0")}
                    </Descriptions.Item>
                    <Descriptions.Item label="MOQ" span={1}>
                      {value.moq}
                    </Descriptions.Item>
                    <Descriptions.Item label="Target Price" span={1}>
                      {value.target_price}
                    </Descriptions.Item>
                    <Descriptions.Item label="Dimension" span={1}>
                      {value.dimension}
                    </Descriptions.Item>
                    <Descriptions.Item label="ID/OD" span={1}>
                      {value.id_od}
                    </Descriptions.Item>
                    <Descriptions.Item label="Commodity" span={1}>
                      {value.m_commodity}
                    </Descriptions.Item>

                    <Descriptions.Item label="Flute/Foam Type" span={1}>
                      {value.m_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Material Color" span={1}>
                      {value.m_color}
                    </Descriptions.Item>
                    <Descriptions.Item label="PC/Density" span={1}>
                      {value.papercomb_density}
                    </Descriptions.Item>
                    <Descriptions.Item label="Quotation" span={1}>
                      {value.rfq_status !== "W/ REQUEST" &&
                      value.rfq_status !== "W/ REMARKS" &&
                      value.rfq_status !== "NO REQUEST" ? (
                        <span
                          onClick={() =>
                            viewAttachment({
                              id: value.rfq_id,
                              type: "Quotation",
                              product_id: value.id,
                            })
                          }
                          style={{ color: "#3A7FDA", cursor: "pointer" }}
                        >
                          {value.rfq_status}
                        </span>
                      ) : (
                        value.rfq_status
                      )}
                      {value.rfqaging}
                    </Descriptions.Item>
                    <Descriptions.Item label="Drawing" span={1}>
                      {value.rfd_status !== "W/ REQUEST" &&
                      value.rfd_status !== "W/ REMARKS" &&
                      value.rfd_status !== "NO REQUEST" ? (
                        <span
                          onClick={() =>
                            viewAttachment({
                              id: value.rfd_id,
                              type: "Drawing",
                              product_id: value.id,
                            })
                          }
                          style={{ color: "#3A7FDA", cursor: "pointer" }}
                        >
                          {value.rfd_status}
                        </span>
                      ) : (
                        value.rfd_status
                      )}
                      {value.rfdaging}
                    </Descriptions.Item>
                    <Descriptions.Item label="Sample" span={1}>
                      {value.rfs_status !== "W/ REQUEST" &&
                      value.rfs_status !== "W/ REMARKS" &&
                      value.rfs_status !== "NO REQUEST" ? (
                        <span
                          onClick={() =>
                            viewAttachment({
                              id: value.rfs_id,
                              type: "Sample",
                              product_id: value.id,
                            })
                          }
                          style={{ color: "#3A7FDA", cursor: "pointer" }}
                        >
                          {value.rfs_status}
                        </span>
                      ) : (
                        value.rfs_status
                      )}
                      {value.rfsaging}
                    </Descriptions.Item>
                    <Descriptions.Item label="OVERALL STATUS" span={2}>
                      {(isCS &&
                        value.editableStatus &&
                        value.status === "ON GOING") ||
                      isAdmin ? (
                        <Select
                          onBlur={() => onClickStatus(value)}
                          onChange={(val) =>
                            confirmAction(
                              editNewProduct,
                              null,
                              { ...value },
                              { ...value, status: val },
                              () => {}
                            )
                          }
                          value={value.status}
                          style={{ width: "100%" }}
                        >
                          {opt}
                        </Select>
                      ) : (
                        <Text
                          onClick={() =>
                            (isCS && value.status === "ON GOING") || isAdmin
                              ? onClickStatus(value)
                              : null
                          }
                          code
                        >
                          {value.status}
                        </Text>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="REMARKS" span={1}>
                      {(isCS && value.status === "CLOSED") || isAdmin ? (
                        <Text
                          editable={{
                            onChange: (val) =>
                              confirmAction(
                                editNewProduct,
                                null,
                                { ...value },
                                { ...value, rejectionremarks: val },
                                () => {}
                              ),
                          }}
                        >
                          {rejectremarks ? rejectremarks : ""}
                        </Text>
                      ) : (
                        value.rejectionremarks
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </List.Item>
              );
            }}
          />
          <div style={{ textAlign: "right" }}>
            <Pagination
              current={paginate.current}
              total={productLength}
              pageSize={paginate.pageSize}
              defaultPageSize={paginate.pageSize}
              showSizeChanger={true}
              onChange={onPageChange}
              onShowSizeChange={onSizeChange}
              pageSizeOptions={["5", "10", "15", "25", "50"]}
            />
          </div>

          {viewTimeline.view ? (
            <ProductTimeline
              viewTimeline={viewTimeline}
              setViewTimeline={setViewTimeline}
            />
          ) : null}
          {viewRequest.view ? (
            <RequestModal
              isAdmin={isAdmin}
              setViewRequest={setViewRequest}
              viewRequest={viewRequest}
            />
          ) : null}

          {viewAttachFiles.view ? (
            <AttachmentModal
              viewAttachFiles={viewAttachFiles}
              setViewAttachFiles={setViewAttachFiles}
            />
          ) : null}
        </Fragment>
      ) : (
        <Empty description="EMPTY RECORD" style={{ height: "300px" }} />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  products: state.NewProduct.products,
  productLength: state.NewProduct.productLength,
  selectOptions: state.Utils.selectOptions,
  isSelectOptionLoaded: state.Utils.isSelectOptionLoaded,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      displayNewProduct,
      getSelectOption,
      editNewProduct,
      deleteNewProduct,
      updateProductList: (updatedProduct) => ({
        type: EDIT_PRODUCT,
        payload: {
          updatedProduct,
          id: updatedProduct.id,
        },
      }),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Itemlist);
