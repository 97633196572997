import React from 'react';
import { Modal } from 'antd';

import ItemForm from './ItemForm';

function ItemFormModal(props) {
  const { productModalVisible, closeProductModal, itemInfo, type } = props;
  return (
    <Modal
      style={{ top: 20 }}
      width="90%"
      visible={productModalVisible}
      onCancel={closeProductModal}
      destroyOnClose={true}
      title={`${type} ITEM`}
      footer={null}
    >
      {productModalVisible ?
        <ItemForm closeProductModal={closeProductModal} type={type} itemInfo={itemInfo} />
        : null
      }
      {/* <Timeline>
        <Timeline.Item color="green">Create a services site 2015-09-01</Timeline.Item>
        <Timeline.Item color="green">Create a services site 2015-09-01</Timeline.Item>
        <Timeline.Item color="red">
          <p>Solve initial network problems 1</p>
          <p>Solve initial network problems 2</p>
          <p>Solve initial network problems 3 2015-09-01</p>
        </Timeline.Item>
        <Timeline.Item>
          <p>Technical testing 1</p>
          <p>Technical testing 2</p>
          <p>Technical testing 3 2015-09-01</p>
        </Timeline.Item>
      </Timeline> */}
    </Modal>
  )
}

export default ItemFormModal
