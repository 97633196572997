import { Modal } from 'antd';
const confirm = Modal.confirm;

export const confirmAction = (okAction, cancelAction, ...props) => {

  confirm({
    title: 'Confirmation',
    content: 'Do you really wish to continue?',
    onOk: () => okAction(...props, cancelAction),
    okText: 'Confirm',
    onCancel: () => cancelAction ? cancelAction(false) : null,
    confirmLoading: true
  })
}