import React, { Fragment } from 'react';
import { Table, Typography } from 'antd';

const { Text } = Typography;
function StatsList(prop) {
  const { data } = prop;
  const columns = [
    {
      width: '20%',
      key: 'type',
      render: data => <Text strong>{data.type}</Text>
    },
    {
      align: 'left',
      key: 'rfq',
      title: 'REQUEST FOR QUOTATION',
      render: data => <Fragment>{data.rfq}  {data.rfqp ? <Text strong>({data.rfqp})</Text> : null}</Fragment>
    },
    {
      align: 'left',
      key: 'rfd',
      title: 'REQUEST FOR DRAWING',
      render: data => <Fragment>{data.rfd}  {data.rfdp ? <Text strong>({data.rfdp})</Text> : null}</Fragment>
    },
    {
      align: 'left',
      key: 'rfs',
      title: 'REQUEST FOR SAMPLE',
      render: data => <Fragment>{data.rfd}  {data.rfdp ? <Text strong>({data.rfsp})</Text> : null}</Fragment>
    },
  ]

  return (
    <Table
      pagination={
        {
          hideOnSinglePage: true
        }
      }
      bordered={true}
      size="middle"
      dataSource={data.stats}
      columns={columns}
      rowKey="type"
    />
  )
}

export default StatsList
