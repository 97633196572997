import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { brand } from '../../variables/routes';

function Title(props) {
    return (
      <Fragment>
        <span className="title"><Link to={brand.path}>Dashboard</Link> > <Link to={props.pathname} ><small>{props.label}</small></Link></span>
      </Fragment>
    )
  }

export default Title
