import React from 'react';
import { Icon, Dropdown, Menu } from 'antd';
import decode from 'jwt-decode';
import { logout, getToken } from '../../variables/Variables';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="/" onClick={logout}>Logout</a>
    </Menu.Item>
  </Menu>
);

function Header(props) {
  const token = getToken();
  // const { toggleMenu, isHidden } = props;
  const decoded = token ? decode(token) : { username : 'Dummy user' };

  return (
    <div className="header">
      <div className="brand-name">NEW PRODUCT DEVELOPMENT</div>
      {/* <Icon title="Toggle Menu" onClick={toggleMenu} type={!isHidden ? "menu-fold" : "menu-unfold"} className={!isHidden ? "menufold" : "menufold menufold-hidden"} /> */}
      <div className="username">
        <Dropdown overlay={menu} trigger={['click']}>
          <span className="username-info" style={{ cursor : 'pointer' }}><Icon type="user" />{decoded.username} <Icon type="down" /></span>
        </Dropdown>
      </div>
    </div>
  )
}

export default Header
