import {
  ADD_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT
} from './newproduct.type';
import { SET_LOADING, UNSET_LOADING } from '../Utils/utils.type';
import axios from 'axios';
import { API, catchErrors, displayNotification, headers } from '../../variables/Variables';

export const displayNewProduct = (filter, page, pageSize) => dispatch => {

  dispatch({ type: SET_LOADING });
  const { company, projectname, status, commodity } = filter;


  const company_filter = company === 'All' ? '' : `&company=${company}`;
  const status_filter = status === 'All' ? '' : `&status=${status}`;
  const commodity_filter = commodity === 'All' ? '' : `&commodity=${commodity}`;
  const projectname_filter = projectname.trim() === '' ? '' : `&projectName=${projectname}`;

  axios.get(API + `product?page=${page}&pageSize=${pageSize}`
    + company_filter + status_filter + commodity_filter + projectname_filter, headers())
    .then(res => {
      const { products, productLength } = res.data;

      dispatch({
        type: GET_PRODUCT,
        payload: {
          products,
          productLength
        }
      })
      dispatch({ type: UNSET_LOADING });
    })
    .catch(err => {
      catchErrors(err);
      dispatch({ type: UNSET_LOADING });
    })

}

export const addNewProduct = (values, setSubmitting, closeModal) => dispatch => {

  dispatch({ type: SET_LOADING });

  axios.post(API + 'product', values, headers())
    .then(res => {
      const { newProduct } = res.data;
      displayNotification('success', 'Record successfully added');
      dispatch({
        type: ADD_PRODUCT,
        payload: {
          newProduct
        }
      })

      dispatch({ type: UNSET_LOADING });
      setSubmitting(false);
      closeModal();
    })
    .catch(err => {
      catchErrors(err);
      dispatch({ type: UNSET_LOADING });
      setSubmitting(false);
    })

}

export const editNewProduct = (oldValues, values, closeModal, setSubmitting) => dispatch => {

  dispatch({ type: SET_LOADING });
  // console.log(oldValues);
  // console.log(values);
  axios.put(API + `product/${values.id}`, { initialValues: oldValues, values }, headers())
    .then(res => {

      const { updatedProduct } = res.data;
      displayNotification('success', 'Record successfully updated');
      // console.log(res.data);
      dispatch({
        type: EDIT_PRODUCT,
        payload: {
          updatedProduct,
          id: updatedProduct.id
        }
      })
      dispatch({ type: UNSET_LOADING });
      if (setSubmitting) setSubmitting(false);
      closeModal();
    })
    .catch(err => {
      catchErrors(err);
      dispatch({ type: UNSET_LOADING });
      if (setSubmitting) setSubmitting(false);
    })

}

export const deleteNewProduct = id => dispatch => {

  dispatch({ type: SET_LOADING });

  axios.delete(API + `product/${id}`, headers())
    .then(res => {

      const { message } = res.data;
      displayNotification('success', message);
      dispatch({
        type: DELETE_PRODUCT,
        payload: {
          id
        }
      })
      dispatch({ type: UNSET_LOADING });
    })
    .catch(err => {
      console.log(err);
      catchErrors(err);
      dispatch({ type: UNSET_LOADING });
    })

}