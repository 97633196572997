import Item from '../views/Item';
import Reporting from '../views/Reporting';

export const _layout = '/dashboard';
export const _homepage = _layout + '/npd';

export const brand = {
  name: 'NPD',
  path: _homepage
};



//check available icons @ https://ant.design/components/icon/
export const routes = [
  {
    label: 'NPD ITEMS',
    icon: 'table',
    path: '/npd',
    layout: _layout,
    component: Item,
    displayOnMenu: false, //SET TO  TRUE IF YOU WANT THIS ROUTE TO BE DISPLAYED ON SIDEBAR MENU
  },
  {
    label: 'REPORTING',
    icon: 'table',
    path: '/reporting',
    layout: _layout,
    component: Reporting,
    displayOnMenu: false, //SET TO  TRUE IF YOU WANT THIS ROUTE TO BE DISPLAYED ON SIDEBAR MENU
  }
]
