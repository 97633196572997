import React from 'react';
import { Link } from 'react-router-dom';

function Error404() {
  const styles = {
    main: {
      textTransform: 'uppercase',
      textAlign: 'center',
      width: '100%',
      height: '50%',
      fontSize: 56,
      lineHeight : 1,
      margin : '20px'
    }
  }

  return (
    <div className="wrapper">
      <div style={styles.main}>
        <p>error 404</p>
        <small>PAGE NOT FOUND</small>
        <br/>
        <Link to="/" style={{ fontSize : 24 }} >Go back</Link>
      </div>
    </div>
  )
}

export default Error404
