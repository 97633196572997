import { SET_LOADING, UNSET_LOADING, GET_SELECTOPTION } from './utils.type';


const initialState = {
  isFetching: false,
  selectOptions: {},
  isSelectOptionLoaded: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SELECTOPTION:
      return {
        ...state,
        selectOptions: action.payload.selectOptions,
        isSelectOptionLoaded: true,
        isFetching: false
      }
    case SET_LOADING:
      return {
        ...state,
        isFetching: true
      }
    case UNSET_LOADING:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state;
  }
}