import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isLoggedIn, logout } from '../variables/Variables';
import Error404 from '../components/404/Error404';

// components
import Loader from '../components/Loader/Loader';
// import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import '../assets/css/Layout.css';
import { BackTop } from 'antd';

//routes
import { routes, _layout, _homepage } from '../variables/routes';

export class PageLayout extends Component {

  state = {
    isMenuHidden: true
  };

  toggleMenu = () => {
    // this.setState({ isMenuHidden: !this.state.isMenuHidden });
  }

  getRoutes = routes => {
    return routes.map((props, key) => {
      if (props.layout === _layout) {

        return (
          <Route
            path={props.layout + props.path}
            render={prop => (
              <props.component
                {...prop}
                label={props.label}
                isMenuHidden={this.state.isMenuHidden}
              />
            )}
            key={key}
            exact
          />
        )
      } else
        return null;
    })
  }

  render() {
    const { isFetching, location: { pathname } } = this.props;
    if (pathname === _layout) {
      return <Redirect to={_homepage} />
    }
    //uncomment this to enable route security by checking expiration dates of tokens
    if (!isLoggedIn()) {
      logout();
      return (null)
    }
    return (
      <div>
        {
          isFetching ?
            <Loader />
            : null
        }
        <Switch>{this.getRoutes(routes)}
          <Route
            render={prop => (
              <Error404 />
            )}/></Switch>

        {/* <Sidebar pathname={pathname} brand={brand} routes={routes} isHidden={this.state.isMenuHidden} /> */}
        <Header toggleMenu={this.toggleMenu} isHidden={this.state.isMenuHidden} />
        <BackTop />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return ({
    isFetching: state.Utils.isFetching,
  })
}

export default connect(mapStateToProps, null)(PageLayout);
