import React, { Fragment } from 'react';
import { Row, Button, Select, Col, Input, Collapse } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

function Filter(props) {
  const {
    selectOptions: { customer, material_commodity },
    filter,
    setFilter,
    onClickFilter,
    onResetFilter,
  } = props;

  const changeVal = (value, name) => {
    setFilter({
      ...filter,
      [name]: value
    })
  }
  return (
    <Fragment>
      <Collapse style={{ marginBottom: '15px' }}>
        <Panel header="ADVANCE FILTER" key="1">
          <Row gutter={16}>
            <Col lg={{ span: 8 }}>
              Customer
              <Select value={filter.company} onChange={val => changeVal(val, 'company')} style={{ width: '100%' }} >
                <Option value="All">All</Option>
                {customer && customer.map(data => <Option key={data.id} value={data.id}>{data.companyname}</Option>)}
              </Select>
            </Col>
            <Col lg={{ span: 8 }}>
              Commodity
              <Select value={filter.commodity} onChange={val => changeVal(val, 'commodity')} style={{ width: '100%' }} >
                <Option value="All">All</Option>
                {material_commodity && material_commodity.map(data => <Option key={data.id} value={data.id}>{data.m_commodity}</Option>)}
              </Select>

            </Col>
            <Col lg={{ span: 8 }}>
              Status
              <Select value={filter.status} onChange={val => changeVal(val, 'status')} style={{ width: '100%' }}>
                <Option value="All">All</Option>
                <Option value="APPROVED PENDING FOR PO">APPROVED PENDING FOR PO</Option>
                <Option value="APPROVED W/ PO">APPROVED W/ PO</Option>
                <Option value="CLOSED">CLOSED</Option>
                <Option value="ON GOING">ON GOING</Option>
              </Select>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: '10px' }}>
            <Col lg={{ span: 8 }}>
              Search
              <Input value={filter.projectname} onChange={e => changeVal(e.target.value, 'projectname')} placeholder="Search project name" />
            </Col>
            <Col lg={{ span: 8 }}>
              <br />
              <Button block icon="filter" onClick={onClickFilter}>Filter</Button>
            </Col>
            <Col lg={{ span: 8 }}>
              <br />
              <Button block icon="undo" onClick={onResetFilter}>Reset</Button>
            </Col>
          </Row>

        </Panel>
      </Collapse>
    </Fragment>
  )
}

export default Filter
