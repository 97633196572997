import map from "lodash/map";
import React from "react";
import { DatePicker, Input, TimePicker, Select, AutoComplete, InputNumber, Checkbox } from "antd";

import { FormItem } from '@jbuschke/formik-antd';

const { Option } = Select;

const CreateAntField = (Component, _fieldType) => ({
  field,
  form,
  hasFeedback,
  label,
  selectOptions,
  keyName,
  displayName,
  submitCount,
  type,
  checkLabel,
  onChangeCallback = () => {},
  ...props
}) => {

  const onInputChange = ({ target: { value } }) =>
    form.setFieldValue(field.name, value);
  const onChange = (v1, v2) => {
    if (_fieldType === 'datepicker')
      form.setFieldValue(field.name, v2)
    else if (_fieldType === 'input')
      form.setFieldValue(field.name, v1.target.value)
    else if (_fieldType === 'check')
      form.setFieldValue(field.name, v1.target.checked)
    else
      form.setFieldValue(field.name, v1);

    onChangeCallback(v1);
  }
  const onBlur = () => form.setFieldTouched(field.name, true);

  if (_fieldType === 'autocomplete') {
    props.filterOption = (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
  }

  let Opt = null;

  if (selectOptions || checkLabel) {

    if (selectOptions) {
      Opt = map(selectOptions, name => <Option key={keyName ? name[keyName] : name}>{displayName ? name[displayName] : name}</Option>);
    } else {
      Opt = checkLabel;
    }
  }

  return (
    <div className="field-container">
      <FormItem
        style={{ marginBottom: 5 }}
        label={label}
        name={field.name}
      >
        <Component
          {...field}
          {...props}
          onBlur={onBlur}
          onChange={type ? onInputChange : onChange}
          style={{ width: '100%' }}
        >
          {Opt}
        </Component>
      </FormItem>
    </div>
  );
};

export const AntSelect = CreateAntField(Select, 'select');
export const AntDatePicker = CreateAntField(DatePicker, 'datepicker');
export const AntInput = CreateAntField(Input, 'input');
export const AntPassword = CreateAntField(Input.Password, 'input');
export const AntTimePicker = CreateAntField(TimePicker, 'timepicker');
export const AntAutoComplete = CreateAntField(AutoComplete, 'autocomplete');
export const AntInputNumber = CreateAntField(InputNumber, 'inputnumber');
export const AntCheckbox = CreateAntField(Checkbox, 'check');

