import React from 'react'
import { Form, Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
// import { FormikDebug } from '@jbuschke/formik-antd';

//custom components

import { addNewProduct, editNewProduct } from '../../redux/NewProduct/newproduct.action';
import { confirmAction } from '../Confirmation/Confirmation';
import { validateItemForm } from '../../validate/validate';
import { AntSelect, AntInput, AntInputNumber } from '../Antfield';

function ItemForm(prop) {
  const {
    itemInfo,
    type,
    closeProductModal,
    addNewProduct,
    editNewProduct,
    selectOptions: { customer, material_color, material_pcd, material_type, material_commodity },
  } = prop;

  const ADD = 'ADD';

  return (
    <Formik
      initialValues={itemInfo}
      validationSchema={validateItemForm}
      onSubmit={(values, { setSubmitting }) => type === ADD
        ?
        confirmAction(addNewProduct, setSubmitting, values, closeProductModal)
        :
        confirmAction(editNewProduct, setSubmitting, itemInfo, values, closeProductModal)}
    >
      {({ values, submitCount, handleSubmit, handleReset }) => {

        //convert to string
        values.customer = values.customer.toString();
        values.material_color = values.material_color.toString();
        values.papercomb_density = values.papercomb_density.toString();
        values.material_type = values.material_type.toString();

        return (
          <Form
            labelCol={{ lg: { span: 8 } }}
            wrapperCol={{ lg: { span: 16 } }}>
            <Row gutter={8}>
              <Col lg={{ span: 12 }}>
                <Field
                  values={values.customer}
                  label="Customer"
                  name="customer"
                  selectOptions={customer}
                  component={AntSelect}
                  submitCount={submitCount}
                  keyName="id"
                  displayName="companyname"
                />

                <Field
                  label="Category"
                  values={values.category}
                  name="category"
                  component={AntSelect}
                  selectOptions={['Existing', 'New Item']}
                  submitCount={submitCount}
                />

                <Field
                  values={values.project_name}
                  label="Project Name"
                  name="project_name"
                  component={AntInput}
                  submitCount={submitCount}
                />

                <Field
                  values={values.item_description}
                  label="Item Description"
                  name="item_description"
                  component={AntInput}
                  submitCount={submitCount}
                />

                <Field
                  values={values.part_number}
                  label="Part Number"
                  name="part_number"
                  component={AntInput}
                  submitCount={submitCount}
                />

                <Field
                  values={values.moq}
                  label="MOQ"
                  name="moq"
                  component={AntInputNumber}
                  submitCount={submitCount}
                  min={0}
                />

                <Field
                  values={values.sales_person}
                  label="Sales Person"
                  name="sales_person"
                  component={AntInput}
                  submitCount={submitCount}
                />
              </Col>
              <Col lg={{ span: 12 }}>
                <Field
                  values={values.dimension}
                  label="Dimension(LxWxH)"
                  name="dimension"
                  component={AntInput}
                  submitCount={submitCount}
                />

                <Field
                  values={values.id_od}
                  label="ID/OD"
                  name="id_od"
                  component={AntSelect}
                  selectOptions={['ID', 'OD', 'NONE']}
                  submitCount={submitCount}
                />

                <Field
                  values={values.commodity}
                  label="Commodity"
                  name="commodity"
                  component={AntSelect}
                  selectOptions={material_commodity}
                  submitCount={submitCount}
                  keyName="id"
                  displayName="m_commodity"
                />

                <Field
                  values={values.material_type}
                  label="Material Type"
                  name="material_type"
                  component={AntSelect}
                  selectOptions={material_type}
                  submitCount={submitCount}
                  keyName="id"
                  displayName="m_type"
                />

                <Field
                  values={values.material_color}
                  label="Material Color"
                  name="material_color"
                  component={AntSelect}
                  selectOptions={material_color}
                  submitCount={submitCount}
                  keyName="id"
                  displayName="m_color"
                />

                <Field
                  values={values.papercomb_density}
                  label="Paper Comb/Density"
                  name="papercomb_density"
                  component={AntSelect}
                  selectOptions={material_pcd}
                  submitCount={submitCount}
                  keyName="id"
                  displayName="m_pcd"
                />

                <Field
                  values={values.target_price}
                  label="Target Price"
                  name="target_price"
                  component={AntInputNumber}
                  submitCount={submitCount}
                />
              </Col>
            </Row>
            <div style={{ textAlign: 'right', marginTop: 10 }}>
              <Button onClick={handleSubmit} style={{ marginRight: 5 }}>Submit</Button>
              <Button onClick={handleReset}>Reset</Button>
            </div>
            {/* <FormikDebug /> */}
          </Form>
        )
      }}

    </Formik>
  )
}

const mapStateToProps = state => ({
  selectOptions: state.Utils.selectOptions,
});

export default connect(mapStateToProps, { addNewProduct, editNewProduct })(ItemForm)
