import React, { Fragment } from 'react';
import { Table, Button, Typography, Select } from 'antd';
import { confirmAction } from '../Confirmation/Confirmation';

const { Text } = Typography;
const { Option } = Select;
function Requestlist(prop) {
  const {
    requests,
    setModalConfig,
    decoded,
    deleteRequest,
    viewAttachment,
    setRequest,
    editRequest,
    isAdmin,
    status } = prop;
  const setEditable = data => {
    const key = requests.findIndex(v => v.id === data.id && v.type === data.type);

    const copyRequest = [...requests];
    copyRequest[key] = { ...copyRequest[key], editableStatus: !copyRequest[key].editableStatus };
    setRequest(copyRequest);

  }

  const column = [
    {
      align: 'center',
      key: 'type',
      dataIndex: 'type',
      title: 'Type'
    },
    {
      align: 'center',
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Date requested'
    },
    {
      align: 'center',
      key: 'date_needed',
      dataIndex: 'date_needed',
      title: 'Date needed'
    },
    {
      align: 'center',
      key: 'remarks',
      dataIndex: 'remarks',
      title: 'Remarks'
    },
    {
      align: 'center',
      key: 'date_issued',
      dataIndex: 'date_issued',
      title: 'Date issued'
    },
    {
      align: 'center',
      key: 'attachment',
      title: 'File',
      render: data => {
        if (data.attachemnt === null)
          return data.attachment;
        else {
          return <span onClick={() => viewAttachment(data)} style={{ color: '#3A7FDA', cursor: 'pointer' }}>{data.attachment}</span>
        }
      }
    },
    {
      align: 'center',
      key: 'engg_remarks',
      dataIndex: 'engg_remarks',
      title: 'Engg Remarks'
    },
    {
      align: 'center',
      key: 'status',
      title: 'Status',
      render: (data) => {
        if ((data.status !== 'REJECTED'
          && status === 'ON GOING'
          && data.status !== 'W/ REMARKS'
          && data.status !== 'W/ REQUEST'
          && decoded().department === 'cs')
          || isAdmin) {
          if (data.editableStatus) {
            return (
              <Fragment>
                <Select
                  onBlur={() => setEditable(data)}
                  onChange={val => confirmAction(editRequest, () => { }, data, () => { }, val)}
                  defaultOpen={true}
                  value={data.status}
                  style={{ width: '100%' }} >
                  <Option value="ISSUED">ISSUED</Option>
                  <Option value="SENT">SENT</Option>
                  <Option value="APPROVED">APPROVED</Option>
                  <Option value="REJECTED">REJECTED</Option>
                </Select>
              </Fragment>)
          } else
            return <Text onClick={() => setEditable(data)} code>{data.status}</Text>
        }
        else
          return data.status
      }
    },
    {
      key: 'actionRemarks',
      align: 'center',
      width: '13%',
      title: 'Remarks',
      render: data => {
        if (data.status === 'REJECTED' && (decoded().department === 'cs' || isAdmin)) {
          return <Text editable={{
            onChange: val =>
              confirmAction(editRequest, () => { }, data, () => { }, null, val)
          }}>
            {data.rejectionremarks ? data.rejectionremarks : ''}
          </Text>
        } else
          return data.rejectionremarks
      }
    }
  ]

  const csAction = [
    {
      width: '2%',
      align: 'center',
      key: 'actionEdit',
      render: data => {
        if (data.attachment === null && data.status === 'W/ REQUEST')
          return <Button style={{ color: '#3A7FDA' }} onClick={() =>
            setModalConfig({ width: 700, title: `EDIT ${data.type.toUpperCase()} REQUEST`, view: 'EDIT', data })} icon="edit"></Button>
        else
          return (null)
      }
    },
    {
      width: '2%',
      align: 'center',
      key: 'actionDelete',
      render: (data) => {
        if (data.status === 'W/ REQUEST' || decoded().type === 'admin')
          return <Button style={{ color: 'indianred' }} onClick={() =>
            confirmAction(deleteRequest, null, data.id, data.type, data.product_id)} icon="delete"></Button>
        else
          return (null)
      }
    }
  ]

  const enggAction = [
    {
      width: '2%',
      align: 'center',
      key: 'actionEdit',
      render: data => {
        if (data.status === 'APPROVED' || data.status === 'REJECTED' || data.status === 'SENT')
          return (null)
        else
          return <Button onClick={() => setModalConfig({ width: 500, title: `RESPOND TO ${data.type.toUpperCase()} REQUEST`, view: 'RESPOND', data })}
            title={`Response to ${data.type.toLowerCase()} request`} style={{ color: '#3A7FDA' }} icon="vertical-left"></Button>
      }
    },
  ]

  if ((decoded().department === 'cs' && status === 'ON GOING') || decoded().type === 'admin') {
    csAction.reverse().map(data => {
      return column.unshift(data);
    });
  }

  if (decoded().department === 'engg' && status === 'ON GOING') {
    enggAction.reverse().map(data => {
      return column.unshift(data);
    });
  }

  return (
    <Table
      size="middle"
      rowKey="key"
      dataSource={requests}
      columns={column}
      bodyStyle={{ overflowX: 'auto' }}
    />
  )
}

export default Requestlist