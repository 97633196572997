import {
  ADD_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT
} from './newproduct.type';

const initialState = {
  products: [],
  productLength: 0,
  isLoaded: false,
}

export default function (state = initialState, action) {
  const { products } = state;
  const copy = [...products];
  const { payload, type } = action;

  switch (type) {

    case GET_PRODUCT:
      return {
        ...state,
        products: payload.products,
        productLength: payload.productLength
      }
    case ADD_PRODUCT:

      products.unshift(payload.newProduct);

      if (products.length > 10) {
        products.length = 10;
      }
      return {
        ...state,
        products
      }
    case EDIT_PRODUCT:
      let index = products.findIndex(data => data.id === payload.id);
      copy[index] = payload.updatedProduct;
      return {
        ...state,
        products: copy
      }
    case DELETE_PRODUCT:
      let i = products.findIndex(data => data.id === payload.id);
      copy.splice(i,1);
      return {
        ...state,
        products: copy
      }
    default:
      return state;

  }

}