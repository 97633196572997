import * as Yup from 'yup';

const msg = {
  required: 'Required field'
}

export const validateItemForm = Yup.object().shape({
  customer: Yup.string()
    .required(msg.required),
  category: Yup.string()
    .required(msg.required),
  project_name: Yup.string()
    .required(msg.required),
  item_description: Yup.string()
    .required(msg.required),
  part_number: Yup.string()
    .required(msg.required),
  moq: Yup.number()
    .transform(cv => isNaN(cv) ? undefined : cv)
    .integer('Must be an integer')
    .min(1, 'Must not be less than 0')
    .required(msg.required)
    .nullable(),
  sales_person: Yup.string()
    .nullable(),
  dimension: Yup.string()
    .required(msg.required),
  id_od: Yup.string()
    .nullable(),
  commodity: Yup.string()
    .required(msg.required),
  material_type: Yup.string()
    .required(msg.required),
  material_color: Yup.string()
    .required(msg.required),
  papercomb_density: Yup.string()
    .required(msg.required),
  target_price: Yup.number()
    .transform(cv => isNaN(cv) ? undefined : cv)
    .min(0, 'Must be greater than 0')
    .positive('Must be positive number')
})

export const validateRequestForm = Yup.object().shape({
  date_needed: Yup.date()
    .when(['type', 'oldDateNeeded'], (type, oldDateNeeded, schema) => {
      return type !== undefined ? schema.min(new Date(new Date(oldDateNeeded) - 864e5)) : schema.min(new Date(Date.now() - 864e5))
    })
    .required(msg.required)
    .test(
      'date_needed',
      'Date needed cannot be set to weekends',
      value => {
        const today = new Date(value);
        return (today.getDay() !== 0 && today.getDay() !== 6)
      })
    .nullable(),
  request_type: Yup.string()
    .when('type', {
      is: val => val === undefined,
      then: Yup.string().required(msg.required),
      otherwise: Yup.string()
    })
})