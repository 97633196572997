import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './views/Login';
import PageLayout from './layouts/PageLayout';
import Error404 from './components/404/Error404';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/dashboard" render={props => <PageLayout {...props} />} />
          <Route path="/" render={props => <Login {...props} />} exact />
          <Route render={props => <Error404 />} />
        </Switch>
      </Router>
    </Provider >

  );
}

export default App;
