import React, { useState } from 'react';
import { Input, Button, Form, List, Icon, notification } from 'antd';
import { confirmAction } from '../Confirmation/Confirmation';


const { TextArea } = Input;

function RespondRequest(prop) {
  const { data, onSubmitResponse, viewAttachment, deleteAttachment } = prop;
  const [values, setValues] = useState({ file: null, remarks: data.engg_remarks });
  const uploadRef = React.createRef();

  const validateData = () => {
    if (values.file !== null || (values.remarks !== null && values.remarks !== ''))
      return true;
    else {
      notification.error({
        description : 'Please add remarks or upload requested file',
        message : 'Error'
      })
      return false;
    }

  }

  return (
    <Form
      labelCol={{ lg: { span: 6 } }}
      wrapperCol={{ lg: { span: 18 } }}>

      {data.attachment !== null && data.attachment !== ''
        ?
        <Form.Item style={{ margin: 0 }} label="Attachment">
          <List
            dataSource={[`${data.type}_Request.pdf`]}
            renderItem={file =>
              (<List.Item>
                <span onClick={() => viewAttachment(data)} style={{ color: '#3A7FDA', cursor: 'pointer' }}>
                  {file}
                </span>
                <Icon
                  onClick={() => confirmAction(deleteAttachment, null, data.id, data.type, data.product_id)}
                  title="Delete attachment"
                  style={{ marginLeft: '10px', color: 'indianred', cursor: 'pointer' }}
                  type="delete" />
              </List.Item>)
            }
          >
          </List>
        </Form.Item>
        :
        <Form.Item style={{ margin: 0 }} label="Upload file">
          <Button onClick={() => uploadRef.current.click()} icon="upload">Click to upload</Button>
        </Form.Item>}

      <Form.Item label="Remarks">
        <TextArea value={values.remarks} onChange={(e) => setValues({ ...values, remarks: e.target.value })} row={2} />
        <div style={{ textAlign: 'right' }}>
          <Button onClick={() => validateData() ? confirmAction(onSubmitResponse, null, values, data) : null}>Submit</Button>
        </div>
        <input
          style={{ display: 'none' }}
          ref={uploadRef}
          onChange={(e) => setValues({ ...values, file: e.target.files[0] })}
          type="file" accept="application/pdf" />
      </Form.Item>
    </Form>
  )
}

export default RespondRequest
