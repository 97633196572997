import { SET_LOADING, UNSET_LOADING, GET_SELECTOPTION } from './utils.type';
import axios from 'axios';
import { API, catchErrors, headers } from '../../variables/Variables';

export const load = () => dispatch => {

  dispatch({ type: SET_LOADING });

}

export const unload = () => dispatch => {

  dispatch({ type: UNSET_LOADING });

}

export const getSelectOption = () => dispatch => {

  axios.get(API + 'product/dropdowns', headers())
    .then(res => {
      const { customer, material_type, material_color, material_pcd, material_commodity } = res.data;

      dispatch({
        type: GET_SELECTOPTION,
        payload: {
          selectOptions: {
            customer,
            material_color,
            material_pcd,
            material_type,
            material_commodity
          }
        }
      })
    })
    .catch(err => {
      catchErrors(err);
      dispatch({ type: UNSET_LOADING });
    })

}