import React from 'react';
import moment from 'moment';
import { Button, Form } from 'antd';
// import { FormikDebug } from '@jbuschke/formik-antd';
import { AntDatePicker, AntInput, AntSelect } from '../Antfield';
import { Formik, Field } from 'formik';
import { validateRequestForm } from '../../validate/validate';

import { confirmAction } from '../Confirmation/Confirmation';

function RequestForm(props) {

  const { initialData, addRequest, editRequest, requests, view } = props;
  const selectOption = ['Drawing', 'Sample'];

  const checkQuotationStatus = requests.filter(option => option.type === 'Quotation'
    && option.status === 'APPROVED').length;
  const checkQuoteExistence = requests.filter(option => option.type === 'Quotation'
    && option.status === 'REJECTED').length;
  let newOpt = null;

  if (checkQuoteExistence > 0 || requests.length === 0) {
    newOpt = ['Quotation'];
  }
  if (checkQuotationStatus > 0) {
    newOpt = selectOption.filter(option => {
      if (requests.findIndex(data => data.type === option && (data.status !== 'REJECTED'/* || data.status !== 'CANCELLED'*/)) === -1) {
        return option;
      }
      return false;
    })
  }

  let reqType = null;

  if (view === 'ADD') {
    reqType = <Field
      component={AntSelect}
      name="request_type"
      label="Request for"
      selectOptions={newOpt}
    />
  }

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) => {
        view === 'ADD' ? confirmAction(addRequest, setSubmitting, values)
          : confirmAction(editRequest, setSubmitting, values)
      }}
      initialValues={{ ...initialData, oldDateNeeded: initialData.date_needed }}
      validationSchema={validateRequestForm} >
      {({ values, handleSubmit, handleReset, submitCount }) => (
        <Form
          labelCol={{ lg: { span: 6 } }}
          wrapperCol={{ lg: { span: 18 } }}>
          {reqType}

          <Field
            component={AntDatePicker}
            name="date_needed"
            label="Date needed"
            value={values.date_needed ? moment(values.date_needed, 'YYYY-MM-DD') : null}
            format="YYYY-MM-DD"
            submitCount={submitCount}
            disabledDate={(current) => {
              return current
                && current < moment((d => new Date(values.type === undefined ? d.setDate(d.getDate() - 1) : new Date(values.oldDateNeeded) - 864e5))(new Date()), "YYYY-MM-DD")
            }}
            style={{ width: '100%' }}
          />
          <Field
            component={AntInput}
            name="remarks"
            label="Remarks"
            style={{ width: '100%' }}
          />
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            <Button onClick={handleSubmit} style={{ marginRight: 5 }}>Submit</Button>
            <Button onClick={handleReset}>Reset</Button>
          </div>
          {/* <FormikDebug /> */}
        </Form>
      )}
    </Formik >
  )
}

export default RequestForm
