import React, { useState, useEffect, createRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { SET_LOADING, UNSET_LOADING } from '../../redux/Utils/utils.type';
import { Modal, Button, List, Icon } from 'antd';
import { API, catchErrors, headers, displayNotification, decoded } from '../../variables/Variables';
import { confirmAction } from '../Confirmation/Confirmation';

function AttachmentModal(props) {
  const { viewAttachFiles, setViewAttachFiles, load, unload } = props;
  const fileupload = createRef();

  const isCs = decoded().department === 'cs' ? true : false;
  const isAdmin = decoded().department === 'admin' ? true : false;

  useEffect(() => fetchAttachments(), []);

  const [attachments, setAttachments] = useState([]);

  const onChange = file => {
    if (file === undefined || file === null)
      return false;

    load();
    const data = new FormData();
    data.append('attachment', file);
    data.append('id', viewAttachFiles.id);

    axios.post(API + `product/attachment`, data, headers())
      .then(res => {
        const { message, attachment } = res.data;
        displayNotification('success', message);
        const a_copy = attachments;
        a_copy.unshift(attachment)
        setAttachments([...a_copy]);
        unload();
      })
      .catch(err => {
        catchErrors(err);
        unload();

      })
  }

  const downloadAttachment = (id, name) => {
    load();
    axios({
      url: API + `product/attachment/${id}`,
      method: 'GET',
      responseType: 'blob', // important
      ...headers()
    }).then((response) => {
      //Create a Blob from the PDF Stream
      //to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.parentElement.removeChild(link);
      unload();

    })

  }

  const deleteAttachment = (id) => {
    load();
    axios.delete(API + `product/attachment/${id}`, headers())
      .then(res => {
        const { message } = res.data;
        displayNotification('success', message);
        const a_copy = attachments;
        const index = a_copy.findIndex(val => val.id === id);
        a_copy.splice(index, 1);
        setAttachments([...a_copy]);
        unload();
      })
      .catch(err => {
        catchErrors(err);
        unload();
      })
  }

  const fetchAttachments = () => {

    axios.get(API + `product/attachment?id=${viewAttachFiles.id}`, headers())
      .then(res => {
        setAttachments(res.data.attachments);
      })
      .catch(err => {
        catchErrors(err);
      })

  }

  return (
    <Modal
      footer={null}
      visible={viewAttachFiles.view}
      onCancel={() => setViewAttachFiles({ view: false, id: null })}
      title="Attachments"
      width={400}
    >
      {isCs || isAdmin ?
        <Button
          onClick={() => fileupload.current.click()}
          icon="upload" block>Upload attachment</Button>
        : null}
      <br />
      <br />
      <List
        size="small"
        header={<div>List of attachments for this item.</div>}
        bordered
        dataSource={attachments}
        renderItem={data => <List.Item
          key={data.id}>
          <span
            onClick={() => downloadAttachment(data.id, data.filename)}
            style={
              {
                cursor: 'pointer',
                color: '#0000EE'
              }
            }>{data.filename}</span>
          {isCs || isAdmin ?
            <Icon onClick={() => confirmAction(deleteAttachment, null, data.id)}
              style={{ color: 'indianred', marginLeft: 'auto' }} type="delete" />
            : null}
        </List.Item>}
      />

      <input
        onChange={e => onChange(e.target.files[0])}
        style={{ display: 'none' }}
        ref={fileupload}
        type="file"
        name="upload_attachment" />
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  load: () => dispatch({ type: SET_LOADING }),
  unload: () => dispatch({ type: UNSET_LOADING }),
});

export default connect(null, mapDispatchToProps)(AttachmentModal)
