import React, { useState } from 'react';

import Title from '../components/Title/Title';
import Itemlist from '../components/Items/Itemlist';
import ItemFormModal from '../components/Items/ItemFormModal';

const ADD = 'ADD';
const EDIT = 'EDIT';

function Item(props) {

  const defaultState = {
    productModalVisible: false,
    itemInfo: defaultValue,
    type: ADD
  }

  const [_state, _setState] = useState(defaultState);

  const closeProductModal = () => {
    _setState({
      ..._state,
      productModalVisible: false,
      itemInfo: defaultValue,
      type: ADD
    })
  }

  const onSelectProduct = itemInfo => {

    _setState({
      ..._state,
      productModalVisible: true,
      itemInfo,
      type: EDIT
    })
  }

  const { label, location: { pathname }, isMenuHidden } = props;
  return (
    <div className={isMenuHidden ? "content content-hidden" : "content"}>
      {/* <Title pathname={pathname} label={label} /> */}

      <div className="wrapper">
        <Itemlist onSelectProduct={onSelectProduct} _setState={_setState} _state={_state} />
      </div>

      {/* modal */}
      <ItemFormModal
        closeProductModal={closeProductModal}
        {..._state} />
    </div>
  )
}

const defaultValue = {
  customer: '',
  category: '',
  project_name: '',
  item_description: '',
  part_number: '',
  moq: '',
  sales_person: '',
  dimension: '',
  id_od: '',
  commodity: '',
  material_type: '',
  material_color: '',
  papercomb_density: '',
  target_price: '',
}

export default Item;
