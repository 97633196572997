import React, { Fragment, useState, useEffect } from 'react';
import {
  API,
  headers,
  displayNotification,
  catchErrors,
  decoded
} from '../../variables/Variables';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SET_LOADING, UNSET_LOADING } from '../../redux/Utils/utils.type';
import { EDIT_PRODUCT } from '../../redux/NewProduct/newproduct.type';
import { Modal, Button } from 'antd';

import RequestForm from './RequestForm';
import Requestlist from './Requestlist';
import RespondRequest from './RespondRequest';

function RequestModal(prop) {
  const { viewRequest, setViewRequest, load, unload, updateProductList, isAdmin } = prop;

  const { status } = viewRequest;

  const fetchRequest = () => {
    load();
    axios.get(API + `product/request/${viewRequest.id}`, headers())
      .then(res => {
        setRequest(res.data.requests);
        unload();
      })
      .catch(err => {
        catchErrors(err);
        unload();
      });
  }

  const addRequest = (values, setSubmitting) => {
    load();

    axios.post(API + `product/request`, values, headers())
      .then(res => {
        const { newRequest, newProduct } = res.data;
        updateProductList(newProduct);//update status on product
        setRequest([...requests, newRequest]);//add new record
        setModalConfig(defaultConfig);  //back to list(default)
        setSubmitting(false);
        displayNotification('success', 'Successfully created request');
        unload();
      })
      .catch(err => {
        setSubmitting(false);
        catchErrors(err);
        unload();
      });

  }

  const editRequest = (values, setSubmitting, newStatus, newRemarks) => {

    // load();
    if (newStatus)
      values.status = newStatus;

    if (newRemarks !== null)
      values.rejectionremarks = newRemarks;

    axios.put(API + `product/request/${values.id}`, values, headers())
      .then(res => {
        const { newRequest, newProduct } = res.data;

        const requestsCopy = [...requests];//make a copy of request
        const index = requestsCopy.findIndex(data => data.id === newRequest.id
          && data.type === newRequest.type);//finx index
        requestsCopy[index] = newRequest;//updated product list
        setRequest(requestsCopy);//set new request
        setModalConfig(defaultConfig);
        updateProductList(newProduct);
        setSubmitting(false);
        displayNotification('success', 'Changes successfully saved');
        unload();
      })
      .catch(err => {
        setSubmitting(false);
        catchErrors(err);
        unload();
      });
  }

  const deleteRequest = (id, type, product_id) => {

    load();
    fetch(API + `product/request/${id}/${type}/${product_id}`, {
      method: 'delete',
      ...headers(),
    }).then(res => res.json())
      .then(data => {
        const { newProduct } = data;
        const requestsCopy = [...requests];//copy request
        const index = requestsCopy.findIndex(data => data.id === id && data.type === type); //get index from request
        requestsCopy.splice(index, 1); //remove deleted req
        updateProductList(newProduct); //updated product list
        setRequest(requestsCopy); //set new request
        setModalConfig(defaultConfig);
        displayNotification('success', 'Record successfully deleted');
        unload();
      })
      .catch(err => {
        catchErrors(err);
        unload();
      });

  }


  const onSubmitResponse = (values, data) => {

    var d = new FormData();
    if (values.file !== null) {
      //check if pdf
      if (values.file.name.split('.').pop() !== 'pdf') {
        displayNotification('error', 'File must be PDF');
        return false;
      } else
        d.append('file', values.file);
    }
    d.append('type', data.type);
    d.append('product_id', data.product_id);
    d.append('remarks', values.remarks ? values.remarks : '');
    load();

    axios.post(API + `product/request/${data.id}/reply`, d, headers())
      .then(res => {
        const { newRequest, newProduct } = res.data;
        const requestsCopy = [...requests];//make a copy of request
        const index = requestsCopy.findIndex(data => data.id === newRequest.id
          && data.type === newRequest.type);//finx index
        requestsCopy[index] = newRequest;//updated req list
        updateProductList(newProduct); //updated product list
        setRequest(requestsCopy);//set new request
        setModalConfig(defaultConfig);
        unload();
      })
      .catch(err => {
        catchErrors(err);
        unload();
      });
  }

  const deleteAttachment = (id, type, product_id) => {

    load();
    axios.delete(API + `product/request/attachment/${id}/${type}/${product_id}`, headers())
      .then(res => {
        const { newRequest, newProduct } = res.data;
        const requestsCopy = [...requests];//make a copy of request

        const index = requestsCopy.findIndex(data => data.id === newRequest.id
          && data.type === newRequest.type);//finx index
        requestsCopy[index] = newRequest;//updated product list
        updateProductList(newProduct); //updated product list
        setRequest(requestsCopy);//set new request
        setModalConfig(defaultConfig);
        unload();

      })
      .catch(err => {
        unload();
        catchErrors(err);
      })

  }

  const [requests, setRequest] = useState([]);
  const defaultValues = { date_needed: '', remarks: '', request_type: '', id: viewRequest.id };
  const defaultConfig = { width: 1200, title: 'REQUEST LIST', view: 'LIST', data: defaultValues };
  const [modalConfig, setModalConfig] = useState(defaultConfig);

  useEffect(() => fetchRequest(), []);
  const viewFormConfig = { ...modalConfig, width: 700, title: 'ADD REQUEST', view: 'ADD' };

  return (
    <Modal
      width={modalConfig.width}
      footer={null}
      title={`${modalConfig.title} for ${viewRequest.project_name}`}
      onCancel={() => setViewRequest({ view: false, id: null })}
      destroyOnClose={true}
      visible={viewRequest.view}>
      {/* if list */}
      {modalConfig.view === 'LIST'
        ?
        <Fragment>
          {decoded().department === 'cs' && status === 'ON GOING'
            ?
            <Button
              style={{ marginBottom: '15px' }}
              onClick={() => setModalConfig(viewFormConfig)}>
              ADD REQUEST
            </Button>
            : null}
          <Requestlist
            isAdmin={isAdmin}
            status={status}
            setRequest={setRequest}
            viewAttachment={viewAttachment}
            decoded={decoded}
            editRequest={editRequest}
            deleteRequest={deleteRequest}
            setModalConfig={setModalConfig}
            requests={requests} />
        </Fragment>
        : null}
      {/* add /edit  */}
      {modalConfig.view === 'ADD' || modalConfig.view === 'EDIT' ?
        <Fragment>
          <Button
            style={{ marginBottom: '15px' }}
            onClick={() => setModalConfig(defaultConfig)}>
            BACK
        </Button>
          <RequestForm
            requests={requests}
            editRequest={editRequest}
            addRequest={addRequest}
            view={modalConfig.view}
            initialData={modalConfig.data} />
        </Fragment> : null}
      {/* respond to request */}
      {decoded().department === 'engg' && modalConfig.view === 'RESPOND' ?
        <Fragment>
          <Button
            style={{ marginBottom: '15px' }}
            onClick={() => setModalConfig(defaultConfig)}>
            BACK
          </Button>
          <RespondRequest
            deleteAttachment={deleteAttachment}
            viewAttachment={viewAttachment}
            onSubmitResponse={onSubmitResponse}
            data={modalConfig.data} />
        </Fragment>
        : null}
    </Modal>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    load: () => ({ type: SET_LOADING }),
    unload: () => ({ type: UNSET_LOADING }),
    updateProductList: (updatedProduct) => (
      {
        type: EDIT_PRODUCT,
        payload: {
          updatedProduct,
          id: updatedProduct.id
        }
      })
  }, dispatch);


export const viewAttachment = (data) => {
  // load();
  axios({
    url: API + `product/request/attachment/${data.id}/${data.type}/${data.product_id}`,
    method: 'GET',
    responseType: 'blob', // important
    ...headers()
  }).then((response) => {

    //Create a Blob from the PDF Stream
    const file = new Blob(
      [response.data],
      { type: 'application/pdf' });

    const url = URL.createObjectURL(file);
    window.open(url);
    // unload();

  })

}
export default connect(null, mapDispatchToProps)(RequestModal)
