import React from 'react';
import '../assets/css/Login.css';
import LoginForm from '../components/LoginForm/LoginForm';
import { isLoggedIn } from '../variables/Variables';
import { _homepage } from '../variables/routes';
import { Redirect } from 'react-router-dom';

function Login() {

  if (isLoggedIn()) {
    return <Redirect to={_homepage} />
  }
  return (
    <div className="logincontainer">
      <LoginForm />
    </div>
  )
}

export default Login
