import axios from 'axios';
import decode from 'jwt-decode';
import { notification } from 'antd';

const _pre = 'https://';

export const API = _pre + 'npd-be.exelpackcorp.com/api/'; //replace this with your api

export const getToken = () => localStorage.getItem('token');

export const setToken = token => localStorage.setItem('token', token);

export const headers = () => {
  const headers = {
    headers: {
      "Authorization": "Bearer " + getToken(),
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  }

  return headers;
}

export const decoded = () => {
  return decode(getToken());
}

export const isLoggedIn = () => {

  const token = getToken();
  if (!!token) {
    if (!isExpired(token))
      return true;
    else {
      return false;
    }

  } else {
    return false;
  }
}

export const isExpired = token => {

  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000)
      return true;
    else
      return false;

  } catch (err) {
    return false;
  }

}

export const logout = () => {
  const token = getToken();

  if (!!token) {

    axios.post(API + 'logout?token=' + getToken())
      .then(res => {
        console.log(res.data.message, 1.5);

      })
      .catch(err => {
        console.log(err.message);
      });

  }
  removeToken();

}

const removeToken = () => {
  localStorage.removeItem('token');
  window.location.href = '/';
}

export const displayNotification = (type, desc) => {
  notification[type]({
    message: type.toString().toUpperCase(),
    description: desc
  })
}


export const catchErrors = (err) => {
  if (!err.response) {
    notification.error({
      message: 'Session Expired! You will be logged out very soon!'
    })

    setTimeout(() => {
      removeToken();
    }, 3000)
  } else {
    const { errors } = err.response.data;
    for (var prop in errors) {
      notification.error({
        message: errors[prop][0]
      });
    }
  }

}
