import React from 'react';
import '../../assets/css/Loader.css';

function Loader() {
  return (
    <div className="loader">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  )
}

export default Loader
