import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SET_LOADING, UNSET_LOADING } from '../redux/Utils/utils.type';

import moment from 'moment';
import Title from '../components/Title/Title';
import SubMenu from '../components/SubMenu/SubMenu';
import StatsList from '../components/Reporting/StatsList';

import { Statistic, Row, Col, DatePicker } from 'antd';
import { API, catchErrors, headers } from '../variables/Variables';
import { Line } from 'react-chartjs-2';

const { RangePicker } = DatePicker;
function Reporting(props) {
  const from = moment().startOf('month');
  const to = moment().endOf('month');
  const [dateFilter, setDateFilter] = useState([from, to]);

  useEffect(() => {
    fetchValues(dateFilter);
  }, dateFilter);

  const { label, location: { pathname }, isMenuHidden, load, unload } = props;

  const defaultValues = {
    stats: [],
    product: {
      productCount: 0,
      productWithPO: 0,
      productPendingPO: 0,
      productClosed: 0,
      productOnGoing: 0
    },
    yearly: {
      totalPerMonth: [],
      totalApproved: [],
      totalApprovedWithPO: [],
      totalClosed: [],
      highest: 0,
      year: '',
    }
  }
  const [values, setValues] = useState(defaultValues);

  const fetchValues = (dateFilter) => {
    const format = 'YYYY-MM-DD';
    const d1 = dateFilter[0].format(format);
    const d2 = dateFilter[1].format(format);

    load();
    fetch(API + `product/reporting?from=${d1}&to=${d2}`, {
      ...headers(),
    })
      .then(res => res.json())
      .then(data => {
        unload();
        setValues(data);
      })
      .catch(err => {
        catchErrors(err);
        unload();
      })

  }

  const { product: { productCount, productWithPO, productPendingPO, productOnGoing, productClosed } } = values;
  const { yearly: { totalPerMonth, totalApproved, totalApprovedWithPO, totalClosed, highest, year } } = values;
  // const labels = ['Count', 'Hit', 'Missed', 'Approved', 'Pending', 'Reject'];
  // const backgroundColor = ['#3498db', '#27ae60', '#e74c3c', '#686de0', '#6ab04c', '#ffbe76'];

  const chart_data = {
    labels: ['Jan', 'Feb', 'Marc', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      label: "Total NPD",
      borderColor: "#74b9ff",
      backgroundColor: "#74b9ff",
      fill: false,
      data: totalPerMonth,
    }, {
      label: 'Approved Pending for PO',
      borderColor: "#6ab04c",
      backgroundColor: "#6ab04c",
      fill: false,
      data: totalApproved,
    }, {
      label: 'Approved with PO',
      borderColor: "#27ae60",
      backgroundColor: "#27ae60",
      fill: false,
      data: totalApprovedWithPO,
    }, {
      label: 'Closed',
      borderColor: "#E85353",
      backgroundColor: "#E85353",
      fill: false,
      data: totalClosed,
    }]
  }

  const chart_opt = {
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Month'
        }
      }],
      yAxes: [{
        display: true,
        ticks: {
          // forces step size to be 5 units
          stepSize: 1,
          suggestedMax: highest
        }
      }],
    },
    title: {
      display: true,
      text: 'NEW PRODUCT DEVELOPMENT RECORD FOR YEAR ' + year
    },
    maintainAspectRatio: false,
  }

  return (
    <div className={isMenuHidden ? "content content-hidden" : "content"}>
      {/* <Title pathname={pathname} label={label} /> */}
      <div className="wrapper">
        <SubMenu />
        <br />
        <div style={{ height: '300px' }}>
          <Line
            data={chart_data}
            options={chart_opt}
          />
        </div>
        <h4>Filter by date</h4>
        <RangePicker
          defaultValue={dateFilter}
          onChange={(d, dd) => setDateFilter(d)}
          format="YYYY-MM-DD"
          allowClear={false}
        />
        <br />
        <br />
        <Row gutter={24}>
          <Col lg={{ span: 19 }}>
            <StatsList data={values} />
          </Col>
          <Col lg={{ span: 5 }}>
            <Statistic title="NPD Item Count" value={productCount} />
            <Statistic title="Approved Pending for PO" value={productPendingPO} />
            <Statistic title="Approved w/ PO" value={productWithPO} />
            <Statistic title="CLOSED" value={productClosed} />
            <Statistic title="ON GOING" value={productOnGoing} />
          </Col>
        </Row>
        <br/>
        <br/>
      </div>

    </div>
  )
}



const mapDispatchToProps = dispatch => ({
  load: () => dispatch({ type: SET_LOADING }),
  unload: () => dispatch({ type: UNSET_LOADING })
})

export default connect(null, mapDispatchToProps)(Reporting)
