import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

function SubMenu() {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/dashboard/npd">New Product Items</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/dashboard/reporting">Reporting</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default SubMenu
