import React, { useEffect, useState, Fragment } from 'react';
import { Timeline, Drawer, Typography, Icon, Skeleton } from 'antd';

import { API, headers } from '../../variables/Variables';

const { Text } = Typography;

function ProductTimeline(prop) {
  const { viewTimeline, setViewTimeline } = prop;
  const [timeline, setTimeline] = useState([]);
  useEffect(() =>
    getTimeline(viewTimeline.id)
    , []);

  const getTimeline = (id) => {

    fetch(API + `product/timeline/${id}`, {
      ...headers()
    }).then(res =>
      res.json()
    ).then(res => {
      setTimeline([...res])
    })

  }

  if (!viewTimeline.view)
    return (null);

  return (
    <Fragment>
      <Drawer
        title="PRODUCT TIMELINE"
        width='40%'
        visible={viewTimeline.view}
        onClose={() => setViewTimeline({ view: false, id: null })}>

        {timeline.length > 0 ?
          <Timeline
            pendingDot={<Icon type="border"></Icon>}
            pending="END OF TIMELINE">
            {timeline.map((data, i) => (
              <Timeline.Item key={i} color={data.color} style={{ lineHeight: 1 }}>
                {data.description.split(",").length - 1 > 0
                  ? data.description.split(",").map(desc => <p key={Math.random(1, 99999)}>{desc}</p>)
                  : <p>{data.description}</p>}
                <p><Text code>{data.username.toUpperCase()}</Text> {data.created_at} </p>
              </Timeline.Item>
            ))}
          </Timeline>
          : <Skeleton active />}
      </Drawer>
    </Fragment>
  )
}

export default ProductTimeline
